<template>
  <b-navbar class="login_nav_item p-md-0">
    <b-navbar-nav :class="{invisible: !isInitialized}">
      <b-nav-item-dropdown title="UserName" right v-if="isLoggedIn">
        <template v-slot:button-content>
          <b-img class="profile_img" alt="" :title="userName" :src="user.photoURL" />
        </template>
        <b-dropdown-item :to="{name:'home'}" title="Home"><HomeIcon class="mr-2" />Home</b-dropdown-item>
        <b-dropdown-item :to="{name:'profile'}" title="Profile"><ProfileIcon class="mr-2" />My Profile</b-dropdown-item>
        <b-dropdown-item :to="{name:'settings'}" title="Settings"><SettingsIcon class="mr-2" />Settings</b-dropdown-item>
        <b-dropdown-item @click="logout" title="Sign Out"><SignoutIcon class="mr-2" />Sign Out</b-dropdown-item>
      </b-nav-item-dropdown>
      <template v-else>
        <b-nav-item :to="{name:'login'}">Sign In</b-nav-item>
        <b-button class="my-1" size="sm" :to="{name:'register'}" variant="outline-primary">Register</b-button>
      </template>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
import { mapState } from 'vuex';
import ProfileIcon from 'mdi-vue/AccountDetailsOutline';
import HomeIcon from 'mdi-vue/HomeAccount';
import SettingsIcon from 'mdi-vue/AccountCogOutline';
import SignoutIcon from 'mdi-vue/LogoutVariant';

export default {
  name: 'LoginNavbarItem',
  components: {
    ProfileIcon, HomeIcon, SettingsIcon, SignoutIcon,
  },
  computed: {
    ...mapState('auth', ['user', 'isLoggedIn', 'isInitialized']),
    userName() {
      return this.user.displayName || this.user.email || this.user.phoneNumber;
    },
  },

  methods: {
    logout() {
      this.$store.dispatch('auth/signOut').then(() => {
        this.$router.replace({ name: 'logout' });
      });
    },
  },

};
</script>

<style lang="stylus" scoped>

.login_nav_item
  .profile_img
    height 1.7rem

</style>
