<template>
  <b-navbar class="NavBar_Lower" toggleable="md" print>

    <b-collapse id="nav_collapse" class="justify-content-end flex-grow-1 flex-md-grow-0" is-nav>
      <b-navbar-nav>
        <b-nav-item :to="{name:'about'}" title="About">About</b-nav-item>
      <b-nav-item-dropdown text="Backend" right>
        <b-dropdown-item :to="{name:'dashboard'}" title="Dashboard">Dashboard</b-dropdown-item>
        <b-dropdown-item :to="{name:'staging'}">Staging</b-dropdown-item>
      </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>

    <b-navbar-toggle target="nav_collapse">
      <MenuIcon class="menu_icon" />
      <CloseIcon class="close_icon" />
    </b-navbar-toggle>

    <b-navbar-brand class="brand_name mr-auto ml-2 d-md-none" :to="{name:'index'}">Wakaru.Org</b-navbar-brand>
    <LoginNavbarItem />

  </b-navbar>
</template>

<script>
import MenuIcon from 'mdi-vue/Menu';
import CloseIcon from 'mdi-vue/Close';
import LoginNavbarItem from '@/components/ui/LoginNavbarItem.vue';

export default {
  name: 'SectionNavbarLower',
  components: { MenuIcon, CloseIcon, LoginNavbarItem },
};
</script>

<style lang="stylus">

.NavBar_Lower
  .brand_name
    transform-origin left top
    transform scale(0, 0)
    transition transform 50ms
  &.fixed-top
    box-shadow 0 0.2rem 0.5rem 0 rgba(0, 0, 0, 0.2)
    .brand_name
      transform scale(1, 1)
  .navbar-toggler
    .close_icon
      display none
    .menu_icon
      display inline-block
    &[aria-expanded="true"]
      .close_icon
        display inline-block
      .menu_icon
        display none

</style>
